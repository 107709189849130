<template>
  <v-dialog v-model="show" fullscreen hide-overlay transition="dialog-bottom-transition">
    <v-card v-if="selectedLawyer.fullName">
      <v-toolbar dark height="64" color="primary">
        <v-btn icon dark @click="show = false">
          <v-icon>close</v-icon>
        </v-btn>
        <v-toolbar-title>{{ selectedLawyer.fullName }}</v-toolbar-title>
      </v-toolbar>
      <v-layout row wrap pt-3>
        <v-flex xs5 sm2 px-2>
          <v-img
            style="border-radius: 4%;"
            aspect-ratio="1"
            alt="avatar"
            :src="selectedLawyer.imageUrl || 'https://cdn0.iconfinder.com/data/icons/user-pictures/100/unknown2-512.png'"
          ></v-img>
        </v-flex>
        <v-flex xs7 px-2>
          <v-chip
            small
            label
            v-for="spec in selectedLawyer.specialization" :key="spec.key"
            color="primary"
            dark
          >{{ spec }}</v-chip>
        </v-flex>
        <v-flex mt-3 pa-2 class="lightBlack" text-xs-center>
          <v-rating
            large
            dense
            readonly
            empty-icon="star"
            background-color="grey"
            color="primary"
            :value="selectedLawyer.reviews | calculateRating"
          ></v-rating>
          <p class="grey--text my-0">{{ selectedLawyer.reviews | calculateRating }} <span> ({{ selectedLawyer.reviews.length }} Reviews)</span></p>
        </v-flex>
        <v-flex mt-3 v-if="selectedLawyer.description">
          <p class="font-weight-medium grey--text title mb-1 pl-4">About Me</p>
          <p class="subheading px-4" v-for="line in selectedLawyer.description.split('\n')" :key="line.key">{{ line }}</p>
        </v-flex>
        <v-flex px-2 mt-3 mb-5 pb-3>
          <p class="font-weight-medium grey--text title mb-1 pl-3">Contact Me</p>
          <v-list>
            <v-list-tile>
              <v-list-tile-action>
                <v-icon color="primary">mail</v-icon>
              </v-list-tile-action>
              <v-list-tile-content>
                <v-list-tile-title>{{ selectedLawyer.email }}</v-list-tile-title>
              </v-list-tile-content>
            </v-list-tile>
            <div v-if="selectedLawyer.contacts.length > 0">
              <v-list-tile>
                <v-list-tile-action>
                  <v-icon color="primary">phone</v-icon>
                </v-list-tile-action>
                <v-list-tile-content v-for="number in selectedLawyer.contacts" :key="number.key">
                  <v-list-tile-title>{{ number }}</v-list-tile-title>
                </v-list-tile-content>
              </v-list-tile>
            </div>
            <div v-if="selectedLawyer.completeAddress">
              <v-list-tile>
                <v-list-tile-action>
                  <v-icon color="primary">location_on</v-icon>
                </v-list-tile-action>
                <v-list-tile-content>
                  <v-list-tile-title>{{ selectedLawyer.completeAddress }}</v-list-tile-title>
                </v-list-tile-content>
              </v-list-tile>
            </div>
          </v-list>
        </v-flex>
      </v-layout>
      <v-footer fixed>
        <v-flex>
          <v-btn
            class="pb-5"
            depressed
            block
            large
            color="primary"
            style="padding-top: 30px;"
            :to="{ name: 'ViewLawyer', params: { lawyerId: selectedLawyer._id }}"
          >View Lawyer</v-btn>
        </v-flex>
      </v-footer>
    </v-card>
  </v-dialog>
</template>

<script>
import calculateRating from '@/mixins/calculateRatingMixin'

export default {
  mixins: [
    calculateRating
  ],
  props: [
    'visible'
  ],
  computed: {
    show: {
      get () {
        return this.visible
      },
      set (value) {
        if (!value) {
          this.$emit('close')
        }
      }
    },
    selectedLawyer () {
      return this.$store.getters['findLawyer/selectedLawyer']
    }
  }
}
</script>
