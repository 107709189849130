<template>
  <div>
    <v-layout justify-center row wrap :mt-5="$vuetify.breakpoint.mdAndUp">
      <v-flex xs12 sm10 xl8 hidden-sm-and-down>
        <LawyerListFilter />
      </v-flex>
    </v-layout>
    <v-layout justify-center row wrap>
      <v-flex xs12 sm10 lg5 xl4 :mb-5="$vuetify.breakpoint.mdAndUp">
        <div class="vuebar-element dropShadow" v-bar="{preventParentScroll: true}">
          <LawyerList :lawyers="this.$store.getters['findLawyer/lawyers']"/>
        </div>
          <v-flex xs12>
            <v-sheet class="dropShadow">
              <div class="py-3 text-xs-center lightBlack">
                <v-pagination
                  circle
                  v-model="page"
                  :length="this.$store.getters['findLawyer/pages']"
                  @input="nextPage"
                ></v-pagination>
              </div>
            </v-sheet>
          </v-flex>
      </v-flex>
      <v-flex xs12 lg5 xl4 hidden-md-and-down>
        <LawyerOverview class="mb-5"/>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import LawyerList from '@/components/FindLawyersComponents/LawyerList'
import LawyerOverview from '@/components/FindLawyersComponents/LawyerOverview'
import LawyerListFilter from '@/components/FindLawyersComponents/LawyerListFilter'

export default {
  components: {
    LawyerList,
    LawyerOverview,
    LawyerListFilter
  },
  data () {
    return {
      page: 1 || this.$store.getters['findLawyer/page'],
      lawyers: {}
    }
  },
  methods: {
    nextPage () {
      this.$store.dispatch('findLawyer/getLawyers', this.page)
        .catch(() => {
          this.$swal({
            type: 'error',
            text: 'Something went wrong with the server!',
            confirmButtonColor: this.$vuetify.theme.primary,
            onOpen: () => { document.activeElement.blur() }
          })
        })
    }
  },
  mounted () {
    this.nextPage()
  }
}
</script>

<style>
.vuebar-element {
  width: 100%;
  max-width: 100%;
}
  .vb > .vb-dragger {
  z-index: 2;
  margin-left: 20px;
  width: 12px;
  right: 0;
}

.vb > .vb-dragger > .vb-dragger-styler {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform: rotate3d(0,0,0,0);
  transform: rotate3d(0,0,0,0);
  -webkit-transition:
    background-color 100ms ease-out,
    margin 100ms ease-out,
    height 100ms ease-out;
  transition:
    background-color 100ms ease-out,
    margin 100ms ease-out,
    height 100ms ease-out;
  background-color: rgba(131, 56, 235, 0);
  margin: 5px 5px 5px 0;
  border-radius: 20px;
  height: calc(100% - 10px);
  display: block;
}

.vb.vb-scrolling-phantom > .vb-dragger > .vb-dragger-styler {
  background-color: rgba(131, 56, 235,.3);
}

.vb > .vb-dragger:hover > .vb-dragger-styler {
  background-color: rgba(131, 56, 235,.5);
  margin: 0px;
  height: 100%;
}

.vb.vb-dragging > .vb-dragger > .vb-dragger-styler {
  background-color: rgba(131, 56, 235,.5);
  margin: 0px;
  height: 100%;
}

.vb.vb-dragging-phantom > .vb-dragger > .vb-dragger-styler {
    background-color: rgba(131, 56, 235,.5);
}
</style>
