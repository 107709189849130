<template>
  <div>
    <v-btn @click="showFilter = true" icon large>
      <v-icon class="white--text">fas fa-filter</v-icon>
    </v-btn>
    <v-dialog v-model="showFilter" fullscreen hide-overlay transition="dialog-bottom-transition">
      <v-card>
        <v-toolbar dark height="64" color="primary">
          <v-btn icon dark @click="showFilter = false">
            <v-icon>close</v-icon>
          </v-btn>
          <v-toolbar-title>Filter Lawyers</v-toolbar-title>
        </v-toolbar>
        <v-layout row wrap px-2 pt-4>
          <v-flex xs12 sm6 md3>
            <v-text-field
              solo
              placeholder="Lawyer's Name"
              append-icon="account_circle"
              v-model="filterValues.name"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 sm6 md3>
            <v-combobox
              solo
              label="Province"
              append-icon="place"
              :items="provinces"
              v-model="filterValues.province"
              @change="populateCities"
            ></v-combobox>
          </v-flex>
          <v-flex xs12 sm6 md2>
            <v-select
              solo
              no-data-text="Select a province first"
              label="City or Municipality"
              append-icon="location_city"
              :items="cities"
              v-model="filterValues.city"
            ></v-select>
          </v-flex>
          <v-flex xs12 sm6 md2>
            <v-select
              :items="sort"
              label="Sort"
              v-model="filterValues.sort"
              solo
              append-icon="sort"
            ></v-select>
          </v-flex>
        </v-layout>
        <v-footer fixed>
          <v-flex >
            <v-btn
              class="pb-5 pt-3"
              depressed
              block
              large
              color="primary"
              @click="filterLawyerList"
            >Search Lawyers</v-btn>
          </v-flex>
        </v-footer>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Philippines from 'philippines'

export default {
  data () {
    return {
      showFilter: false,
      sort: ['Ascending', 'Descending'],
      cities: [],
      provinces: [],
      filterValues: {
        name: '',
        province: '',
        city: '',
        sort: ''
      }
    }
  },
  methods: {
    getProvinces () {
      for (var i = 0; i < Philippines.provinces.length; i++) {
        this.provinces.push(Philippines.provinces[i].name)
      }
      this.provinces.sort()
    },
    populateCities () {
      if (this.filterValues.province) {
        this.cities = []
        var selectedProvinceKey
        for (var i = 0; i < Philippines.provinces.length; i++) {
          if (Philippines.provinces[i].name === this.filterValues.province) {
            selectedProvinceKey = Philippines.provinces[i].key
          }
        }
        for (var x = 0; x < Philippines.cities.length; x++) {
          if (Philippines.cities[x].province === selectedProvinceKey) {
            this.cities.push(Philippines.cities[x].name)
          }
        }
      } else {
        this.filterValues.city = ''
      }
    },
    filterLawyerList () {
      this.$store.dispatch('findLawyer/filterLawyerList', this.filterValues)
    }
  },
  created () {
    this.getProvinces()
  }
}
</script>
