<template>
  <v-sheet v-if="this.$store.getters['findLawyer/selectedLawyer'].fullName" class="dropShadow ml-4">
    <v-layout xs12 row align-center wrap pa-3>
      <v-flex xs6 sm4 md2 justify-center text-xs-center :class="{'pa-1' : $vuetify.breakpoint.smAndUp}">
        <v-img
          style="border-radius: 8%;"
          aspect-ratio="1"
          alt="avatar"
          :src="this.$store.getters['findLawyer/selectedLawyer'].imageUrl || 'https://cdn0.iconfinder.com/data/icons/user-pictures/100/unknown2-512.png'"
        ></v-img>

      </v-flex>
      <v-flex xs6 sm8 md10 pl-3>
        <v-list-tile-content>
          <span class="title font-weight-bold">
            {{ this.$store.getters['findLawyer/selectedLawyer'].fullName }}
            <v-btn small outline color="primary" :to="{ name: 'ViewLawyer', params: { lawyerId: this.$store.getters['findLawyer/selectedLawyer']._id }}">View Lawyer</v-btn>
          </span>
          <span v-if="this.$store.getters['findLawyer/selectedLawyer'].lawOffice" style="color: #707070;"><v-icon small class="pr-2">fa-balance-scale</v-icon>{{ this.$store.getters['findLawyer/selectedLawyer'].lawOffice }}</span>
        </v-list-tile-content>
      </v-flex>
      <v-flex md3>

      </v-flex>
      <v-flex xs12>
        <v-chip
          small
          label
          v-for="spec in this.$store.getters['findLawyer/selectedLawyer'].specialization" :key="spec.key"
          color="primary"
          dark
        >{{ spec }}</v-chip>
      </v-flex>
    </v-layout>
    <v-divider v-if="this.$store.getters['findLawyer/selectedLawyer'].description"></v-divider>
    <v-layout>
      <v-flex xs12 :pt-3="this.$store.getters['findLawyer/selectedLawyer'].description">
        <div v-if="this.$store.getters['findLawyer/selectedLawyer'].description">
          <p class="subheading mx-4" v-for="line in this.$store.getters['findLawyer/selectedLawyer'].description.split('\n')" :key="line.key">{{ line }}</p>
        </div>
        <div class="pa-3" style="background-color: #F5F5F5; border-top: solid 1px #E0E0E0; border-bottom: solid 1px #E0E0E0">
          <span class="title"><v-icon class="mr-5">list_alt</v-icon> Contacts</span>
        </div>
        <div>
          <v-list>
            <v-list-tile>
              <v-list-tile-action>
                <v-icon color="primary">mail</v-icon>
              </v-list-tile-action>
              <v-list-tile-content>
                <v-list-tile-title>{{ this.$store.getters['findLawyer/selectedLawyer'].email }}</v-list-tile-title>
              </v-list-tile-content>
            </v-list-tile>
            <div v-if="this.$store.getters['findLawyer/selectedLawyer'].contacts.length > 0">
              <v-divider inset></v-divider>
              <v-list-tile>
                <v-list-tile-action>
                  <v-icon color="primary">phone</v-icon>
                </v-list-tile-action>
                <v-list-tile-content v-for="number in this.$store.getters['findLawyer/selectedLawyer'].contacts" :key="number.key">
                  <v-list-tile-title>{{ number }}</v-list-tile-title>
                </v-list-tile-content>
              </v-list-tile>
            </div>
            <div v-if="this.$store.getters['findLawyer/selectedLawyer'].completeAddress">
              <v-divider inset></v-divider>
              <v-list-tile>
                <v-list-tile-action>
                  <v-icon color="primary">location_on</v-icon>
                </v-list-tile-action>
                <v-list-tile-content>
                  <v-list-tile-title>{{ this.$store.getters['findLawyer/selectedLawyer'].completeAddress }}</v-list-tile-title>
                </v-list-tile-content>
              </v-list-tile>
            </div>
          </v-list>
        </div>
      </v-flex>
    </v-layout>
  </v-sheet>
</template>

<script>
import dateToWords from '@/mixins/momentDateToWordsMixin'

export default {
  mixins: [
    dateToWords
  ]
}
</script>
