<template>
  <v-sheet max-height="651.5">
    <v-layout wrap>
      <v-flex v-if="!isFetching">
        <v-card-actions class="pa-3 px-3 primary">
          <span class="px-2 headline white--text">Lawyers</span>
          <v-spacer />
          <LawyerListFilterMobile class="hidden-md-and-up"/>
        </v-card-actions>
        <v-list v-if="lawyers.length != 0" two-line subheader class="pb-0">
          <div v-for="(lawyer, index) in lawyers" :key="lawyer.key">
            <v-list-tile
              ripple
              avatar
              @click="selectLawyer(lawyer)"
            >
              <v-list-tile-avatar>
                <v-img
                  aspect-ratio="1"
                  :src="lawyer.imageUrl ? lawyer.imageUrl : 'https://cdn0.iconfinder.com/data/icons/user-pictures/100/unknown2-512.png'"
                ></v-img>
              </v-list-tile-avatar>

              <v-list-tile-content>
                <v-list-tile-title class="body-2">{{ lawyer.fullName }}</v-list-tile-title>
                <v-list-tile-sub-title>{{ lawyer.city }}, {{ lawyer.province }}</v-list-tile-sub-title>
              </v-list-tile-content>

              <v-list-tile-action>
                <v-list-tile-action-text v-if="!$vuetify.breakpoint.xsOnly">
                  <v-rating
                    :small="$vuetify.breakpoint.smAndDown"
                    :value="lawyer.reviews | calculateRating"
                    empty-icon="star"
                    background-color="grey"
                    color="primary"
                    dense
                    readonly
                  ></v-rating>
                  <p class="grey--text text-xs-center my-0">{{ lawyer.reviews | calculateRating }}<span> ({{ lawyer.reviews.length }} Reviews)</span></p>
                </v-list-tile-action-text>
                <v-list-tile-action-text v-if="$vuetify.breakpoint.xsOnly">
                  <p class="grey--text text-xs-center my-0">{{ lawyer.reviews | calculateRating }} <v-icon small color="primary">star</v-icon></p>
                </v-list-tile-action-text>
              </v-list-tile-action>
            </v-list-tile>
          <v-divider
             v-if="index + 1 < lawyers.length"
            :key="index"
          ></v-divider>
          </div>
        </v-list>
        <v-flex v-else px-4 py-3 text-xs-center font-weight-medium grey--text>
          <p class="headline mb-0 error--text">No Lawyers Found</p>
          <v-icon large class="error--text">sentiment_very_dissatisfied</v-icon>
        </v-flex>
      </v-flex>
      <v-flex v-else>
        <content-placeholders>
          <div v-for="i in 5" :key="i.index">
            <content-placeholders-heading :img="true" class="pa-3" />
            <v-divider></v-divider>
          </div>
        </content-placeholders>
      </v-flex>
    </v-layout>
    <MobileLawyerOverview :visible="mobileOverviewVisible" @close="mobileOverviewVisible = false"/>
  </v-sheet>
</template>

<script>
import calculateRating from '@/mixins/calculateRatingMixin'
import MobileLawyerOverview from '@/components/FindLawyersComponents/MobileLawyerOverview'
import LawyerListFilterMobile from '@/components/FindLawyersComponents/LawyerListFilterMobile'

export default {
  components: {
    MobileLawyerOverview,
    LawyerListFilterMobile
  },
  mixins: [
    calculateRating
  ],
  props: [
    'lawyers'
  ],
  data () {
    return {
      mobileOverviewVisible: false
    }
  },
  methods: {
    selectLawyer (lawyer) {
      this.$store.dispatch('findLawyer/setSelectedLawyer', lawyer)
        .finally(() => {
          if (this.$vuetify.breakpoint.mdAndDown) {
            this.mobileOverviewVisible = true
          }
        })
    }
  },
  computed: {
    isFetching () {
      return this.$store.getters['findLawyer/isFetching']
    }
  }
}
</script>
