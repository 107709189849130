<template>
  <v-sheet class="pa-0" style="background-color: #f5f5f5;">
    <v-layout row wrap justify-center align-baseline>
      <v-flex xs12 sm6 md3 pr-2>
        <v-text-field
          solo
          placeholder="Lawyer's Name"
          append-icon="account_circle"
          v-model="filterValues.name"
        ></v-text-field>
      </v-flex>
      <v-flex xs12 sm6 md3 pr-2>
        <v-combobox
          solo
          label="Province"
          append-icon="place"
          :items="provinces"
          v-model="filterValues.province"
          @change="populateCities"
        ></v-combobox>
      </v-flex>
      <v-flex xs12 sm6 md2 pr-2>
        <v-select
          solo
          no-data-text="Select a province first"
          label="City or Municipality"
          append-icon="location_city"
          :items="cities"
          v-model="filterValues.city"
        ></v-select>
      </v-flex>
      <v-flex xs12 sm6 md2 pr-2>
        <v-select
          :items="sort"
          label="Sort Reviews"
          v-model="filterValues.sort"
          solo
          append-icon="sort"
        ></v-select>
      </v-flex>
      <v-flex xs12 sm6 md2>
        <v-btn
          depressed
          block
          large
          color="primary"
          @click="filterLawyerList"
        >Search <v-icon right dark>search</v-icon></v-btn>
      </v-flex>
    </v-layout>
  </v-sheet>
</template>

<script>
import Philippines from 'philippines'

export default {
  data () {
    return {
      sort: ['Ascending', 'Descending'],
      cities: [],
      provinces: [],
      filterValues: {
        name: '',
        province: '',
        city: '',
        sort: ''
      }
    }
  },
  methods: {
    getProvinces () {
      for (var i = 0; i < Philippines.provinces.length; i++) {
        this.provinces.push(Philippines.provinces[i].name)
      }
      this.provinces.sort()
    },
    populateCities () {
      if (this.filterValues.province) {
        this.cities = []
        var selectedProvinceKey
        for (var i = 0; i < Philippines.provinces.length; i++) {
          if (Philippines.provinces[i].name === this.filterValues.province) {
            selectedProvinceKey = Philippines.provinces[i].key
          }
        }
        for (var x = 0; x < Philippines.cities.length; x++) {
          if (Philippines.cities[x].province === selectedProvinceKey) {
            this.cities.push(Philippines.cities[x].name)
          }
        }
      } else {
        this.filterValues.city = ''
      }
    },
    filterLawyerList () {
      this.$store.dispatch('findLawyer/filterLawyerList', this.filterValues)
        .catch(() => {
          this.$swal({
            type: 'error',
            text: 'Something went wrong with the server!',
            confirmButtonColor: this.$vuetify.theme.primary,
            onOpen: () => { document.activeElement.blur() }
          })
        })
    }
  },
  mounted () {
    this.getProvinces()
  }
}
</script>

<style>

</style>
